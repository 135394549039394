export const GET_HOST = "GET_HOST";

export const BLOCK_UNBLOCK_HOST = "BLOCK_UNBLOCK_HOST";

export const CREATE_NEW_HOST = "CREATE_NEW_HOST";
export const SET_CREATE_HOST_DONE = "SET_CREATE_HOST_DONE";
export const UNSET_CREATE_HOST_DONE = "UNSET_CREATE_HOST_DONE";

export const OPEN_HOST_DIALOG = "OPEN_HOST_DIALOG";
export const CLOSE_HOST_DIALOG = "CLOSE_HOST_DIALOG";

export const EDIT_HOST = "EDIT_HOST";
export const SET_UPDATE_HOST_DONE = "SET_UPDATE_HOST_DONE";
export const UNSET_UPDATE_HOST_DONE = "UNSET_UPDATE_HOST_DONE";

export const GET_HOST_ANALYTIC = "GET_HOST_ANALYTIC";

export const GET_SINGLE_HOST_DATA = "GET_SINGLE_HOST_DATA";
export const GET_TOTAL_COIN_OF_ANALYTIC = "GET_TOTAL_COIN_OF_ANALYTIC";

export const GET_LIVE_STREAMING_ANALYTIC = "GET_LIVE_STREAMING_ANALYTIC";
export const GET_LIVE_STREAMING_COIN = "GET_LIVE_STREAMING_COIN";

export const EXTRA_BONUS = "EXTRA_BONUS";

export const GET_AGENCY_WISE_HOST = "GET_AGENCY_WISE_HOST"

export const GET_HOST_PROFILE = "GET_HOST_PROFILE"

